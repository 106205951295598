import * as singleSpa from "single-spa";
import * as SaySimpleAuthenticator from "@saysimple/auth-module";

(async () => {
    // Due to how Cloudfront works, we need to redirect to /inbox/
    if (location.pathname === "/inbox") {
        location.pathname = "/inbox/";
        return;
    }

    const apigatewayEndpoint = process.env.APIGATEWAY_ENDPOINT ?? window["SS_APIGATEWAY_ENDPOINT"];

    await SaySimpleAuthenticator.init(
        `${apigatewayEndpoint}/auth/authenticate`,
        `${apigatewayEndpoint}/auth/refresh`,
        `${apigatewayEndpoint}/auth/expire-token`,
        SaySimpleAuthenticator.getPersistentSession()
    ).catch(async (e) => {
        if (e.message === SaySimpleAuthenticator.SaySimpleAuth.AUTHENTICATION_EXPIRED) {
            await SaySimpleAuthenticator.logout();
            return;
        }
        throw e;
    });

    if (location.hash.length >= 2) {
        singleSpa.navigateToUrl(location.hash.substring(1));
    }

    let isLoggedIn = SaySimpleAuthenticator.isLoggedIn() || false;
    window.addEventListener(SaySimpleAuthenticator.events.LOGGED_IN, (event) => {
        isLoggedIn = true;
        singleSpa.navigateToUrl("/insights");
        window.location.pathname = "/inbox/";
    });

    window.addEventListener(SaySimpleAuthenticator.events.LOGGED_OUT, (event) => {
        isLoggedIn = false;
        singleSpa.navigateToUrl("/login");
    });

    singleSpa.registerApplication({
        name: "@saysimple/mfe-frontend-authentication",
        app: () => System.import("@saysimple/mfe-frontend-authentication"),
        activeWhen: () => !isLoggedIn,
    });

    singleSpa.registerApplication({
        name: "@saysimple/mfe-main-menu",
        app: () => System.import("@saysimple/mfe-main-menu"),
        activeWhen: (location) => isLoggedIn,
    });

    singleSpa.registerApplication({
        name: "@saysimple/message-intelligence-frontend",
        app: () => System.import("@saysimple/message-intelligence-frontend"),
        activeWhen: (location) => location.pathname === "/insights" && isLoggedIn,
    });

    singleSpa.navigateToUrl(isLoggedIn ? "/insights" : "/");
    singleSpa.start();
})();
